import logo from './logo.svg';
import Nav from './components/Nav/Nav';
import './assets/css/nav.css';
import './assets/css/stats.css';
import './assets/css/footer.css';
import './assets/css/invest.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import NewsSummary from './components/News/NewsSummary';
import SliderHome from './components/Slider/Slider';
import Stats from './components/Stats/Stats';
import InfoSection1 from './components/InfoSections/InfoSection1';
import './assets/css/infosection.css';
import InfoSection2 from './components/InfoSections/InfoSection2';
import './assets/css/investments.css';
import InvestmentsSummary from './components/OurInvestments/InvestmentsSummary';
import './assets/css/newssummary.css';
import Footer from './components/footer/Footer';
import {
  Link,
  createBrowserRouter,
} from "react-router-dom";
import Aboutus from './Pages/AboutUs/Aboutus';
import Team from './Pages/Team/Team';
import Investments from './Pages/Investments/Investments';
import Gallery from './Pages/Gallery/Gallery';
import News from './Pages/News/News';
import SingleNews from './Pages/News/SingleNews';
import Contact from './Pages/ContactUs/Contact';
import InvestNow from './Pages/InvestNow/InvestNow';
import ClassAShares from './Pages/InvestNow/ClassAShares';

const Home = () => {

  return <div style={{ position: 'relative' }}>
    <Link to="/invest-now#invest">
      <button className='floating-btn' style={{ position: 'fixed', zIndex: 100, right: 0, top: '50%' }} >
        Invest Now
      </button>
    </Link>
    <Nav />
    <SliderHome />
    <Stats />
    <InfoSection1 />
    <InfoSection2 />
    <InvestmentsSummary show />
    <NewsSummary />
    <Footer />
  </div >
};

export const AppRoutes = [
  {
    path: "/",
    name: 'Home',
    default: true
  },
  {
    path: "/about-us",
    name: 'About Us'
  },
  {
    path: "/our-team",
    name: 'Our Team'
  },
  {
    path: "/investments",
    name: 'Investments'
  },
  {
    path: '/gallery',
    name: 'Gallery'
  },
  {
    path: '/news',
    name: 'News'
  },
  {
    path: '/news/:newsId',
  },
  {
    path: '/contact-us',
    name: 'Contact US'
  },
  {
    path: '/invest-now',
    name: 'Invest Now'
  }
]

export const App = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <Aboutus />
  },
  {
    path: "/our-team",
    element: <Team />
  },
  {
    path: "/investments",
    element: <Investments />
  },
  {
    path: '/gallery',
    element: <Gallery />
  },
  {
    path: '/news',
    element: <News />
  },
  {
    path: '/news/:newsId',
    element: <SingleNews />
  },
  {
    path: '/contact-us',
    element: <Contact />
  },
  {
    path: '/invest-now',
    element: <InvestNow />
  },
  {
    path: '/Class_A',
    element: <ClassAShares/>
  }
]);

