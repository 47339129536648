import React, { useState } from "react";
import Nav from "../../components/Nav/Nav";
import ProgressBar from "@ramonak/react-progress-bar";
import CompanyStructure from "./CompanyStructure";
import News from "../../assets/images/dk.png";
import DkPortfolio from "./DkPortfolio";
import WhyInvest from "./WhyInvest";
import NauficoStructure from "./NafuicoStructure";
import FuneralOpportunity from "./FuneralOpportunity";
import DiasporaOppotunity from "./DiasporaOppotunity";
import { useQuery } from "react-query";
import Fundraiser from "../../api/Fundraiser";
import { useEffect } from "react";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import ValueProposition from "./ValueProposition";
import AgrostrongSection from "./AgrostrongSection";
import AgrostrongOperationStructure from "./AgrostrongOperationStructure";
import AgrostrongProductSuite from "./AgrostrongProductSuite";
import CapitalRequirements from "./CapitalRequirements";
import { Col, Row } from "react-bootstrap";
import Journey from "./Journey";
import AgroJourney from "./AgroJourney";
import Problem from "./Problem";

const encodeBase64 = (data) => {
  if (typeof btoa === "function") {
    return btoa(data);
  } else if (typeof Buffer === "function") {
    return Buffer.from(data, "utf-8").toString("base64");
  } else {
    throw new Error("Failed to determine the platform specific encoder");
  }
};

const ClassAShares = () => {
  const [currency, setCurrency] = useState("ZAR");
  const [state, setState] = useState({});
  const [customAmount, setCustomAmount] = useState();
  const [disabled, setDisabled] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [netcashAmountValue, setNetCashAmount] = useState();

  const currencyFixer = (num) => {
    const numberTobeChanged = isNaN(num) ? num : parseInt(num);
    return numberTobeChanged
      ?.toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const currencyFixe2r = (num) => {
    const numberTobeChanged = isNaN(num) ? num : parseInt(num);
    return numberTobeChanged
      ?.toFixed(2)
      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const sharePriceCalculator = (amount) => {
    const sharePrice = state.fundRaiser?.entity?.sharePrice || 0;
    return parseInt(amount / sharePrice);
  };

  const transactionId = () => {
    const globalName = (state.fundRaiser?.entity?.entityName || "").replace(
      /\s/g,
      ""
    );

    const stringId = globalName.substring(0, 3).toLowerCase();

    const id = stringId + "-" + (Math.floor(Math.random() * 9000000) + 1000000);

    const encoded = encodeBase64(id);

    return { id, encoded };
  };

  useEffect(() => {
    const id = transactionId();

    setTransaction(id);
  }, []);

  const { error, isLoading } = useQuery(
    "investments",
    Fundraiser.fetchFundraiserEntity,
    {
      onSuccess: (response) => {
        const { data } = response;
        const currency =
          data.fundRaiser?.entity?.entityCountry === "South Africa"
            ? "R"
            : "USD";
        const globalName = (data.fundRaiser?.entity?.entityName || "").replace(
          /\s/g,
          ""
        );
        const stringId = globalName.toUpperCase();
        const id =
          stringId + "-" + (Math.floor(Math.random() * 900000000) + 100000000);
        const encoded = encodeBase64(id);
        setTransaction({ id, encoded });
        setState({ ...data, currency });
        console.log(response);
      },
    }
  );

  const addPercentageToAmount = (amount) => {
    const decimal = state.fundRaiser?.charge
      ? state.fundRaiser?.charge / 100
      : 0;
    const addedAmount = parseFloat(amount) * parseFloat(decimal);
    const trueAmount = parseFloat(amount) + addedAmount;
    return parseFloat(amount) + addedAmount;
  };

  const addPercentageAndTrueAmount = (amount) => {
    const decimal = state.fundRaiser?.charge
      ? state.fundRaiser?.charge / 100
      : 0;
    const addedAmount = parseFloat(amount) * parseFloat(decimal);

    if (state.fundRaiser.entity.entityCountry === "South Africa") {
      return parseFloat(amount) + addedAmount;
    } else {
      const trueAmount = parseFloat(amount) + addedAmount;
      const globalRate = state?.globalSettings?.USDTOZARRATE;
      const convertedAmount = trueAmount * globalRate;
      return convertedAmount;
    }
  };

  const hasReminder = (amount) => {
    if (!amount) {
      return true;
    }

    if (parseInt(amount) <= 0) {
      return true;
    }

    const sharePrice = state.fundRaiser?.entity?.sharePrice || 0;
    const answer = amount % sharePrice === 0;
    setDisabled(answer);
    return true;
  };

  const [realAmount, setRealAmount] = useState(0);
  return (
    <div>
      {/* <Nav /> */}
      {isLoading && (
        <div className="invest-main">
          <span class="loader"></span>
        </div>
      )}
      {!isLoading && state.fundRaiser && (
        <div className="invest-now-section" style={{ paddingTop: 80 }}>
          <div style={{ margin: 50 }}>
            <div
              className="text-overline"
              style={{ textAlign: "center", marginTop: 10 }}
            >
              Get started now!
            </div>
            <p className="title" style={{ color: "#000", textAlign: "center" }}>
              Investment options
            </p>
            <p
              className="title"
              style={{ color: "#000", textAlign: "center", fontSize: 13 }}
            >
              The listing price is US${state.fundRaiser?.entity?.sharePrice} (post VFX Listing) per
              share and online transaction fee of {state.fundRaiser?.charge}%{" "}
            </p>
          </div>
          <div className="grid-container" id="invest">
            {(state.fundRaiser?.investmentOptions || []).map((x, index) => {
              const amount = addPercentageToAmount(x.amount);
              const netcashAmount = addPercentageAndTrueAmount(x.amount);
              return (
                <div className="grid-item" key={index}>
                  <div
                    className="text-overline"
                    style={{
                      textAlign: "center",
                      marginTop: 10,
                      color: "#000",
                      fontWeight: 600,
                    }}
                  >
                    {x?.title}
                  </div>
                  <p
                    className="title"
                    style={{
                      color: "#000",
                      textAlign: "center",
                      marginTop: 30,
                      fontSize: 50,
                      textDecoration: "underline",
                      textDecorationColor: "#C0C0C0",
                      textDecorationThickness: 10,
                      fontWeight: "700",
                    }}
                  >
                    {state?.currency}
                    {currencyFixer(addPercentageToAmount(x.amount) || 0)}
                  </p>
                  <p
                    className="desc"
                    style={{ color: "#000", textAlign: "center" }}
                  >
                    You get <strong>{sharePriceCalculator(x.amount)}</strong>{" "}
                    shares in{" "}
                    <strong>{state.fundRaiser?.entity?.entityName}</strong>{" "}
                    (post VFX Listing)
                  </p>
                  <form
                    name="makeP"
                    id="makeP"
                    method="POST"
                    action="https://paynow.netcash.co.za/site/paynow.aspx"
                  >
                    <input
                      type="text"
                      name="m1"
                      value="54580e3d-792b-4d97-8c9b-991f287063fd"
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="m2"
                      value="24ade73c-98cf-47b3-99be-cc7b867b3080"
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="p2"
                      value={transaction?.id}
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="m4"
                      value={transaction?.encoded}
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="m5"
                      value={state?.fundRaiser?._id}
                      hidden
                      edit
                    />
                    <input type="text" name="m6" value={x.amount} hidden edit />
                    <input
                      type="text"
                      name="p3"
                      value={state.fundRaiser?.entity?.entityName}
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="p4"
                      value={netcashAmount}
                      hidden
                      edit
                    />
                    <button type="submit" className="btn btn-secondary">
                      Invest Now
                    </button>
                  </form>
                </div>
              );
            })}
          </div>
          {!isLoading && state.fundRaiser && (
            <div className="custom-investment">
              <div>
                <div
                  className="text-overline"
                  style={{ textAlign: "center", marginTop: 10 }}
                >
                  Get started now!
                </div>
                <p
                  className="title"
                  style={{ color: "#000", textAlign: "center" }}
                >
                  Invest with custom amount
                </p>
              </div>
              <div className="input-container">
                <span style={{ fontSize: 50, fontWeight: "700" }}>US$</span>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    setCustomAmount(addPercentageToAmount(e.target.value));
                    console.log(e.target.value);
                    setNetCashAmount(
                      addPercentageAndTrueAmount(e.target.value)
                    );
                    hasReminder(e.target.value);
                    setRealAmount(e.target.value);
                  }}
                  min={100}
                />
                <span style={{ fontSize: 50, fontWeight: "700" }}>.00</span>
              </div>
              {customAmount > 0 && (
                <p>
                  You get <strong>{sharePriceCalculator(realAmount)}</strong>{" "}
                  shares in{" "}
                  <strong>{state.fundRaiser?.entity?.entityName}</strong> for{" "}
                  <strong>
                    {" "}
                    (post VFX Listing)
                    {state.currency + " " + currencyFixer(customAmount || 0)}
                  </strong>{" "}
                  including transaction charges{" "}
                </p>
              )}
              <form
                name="makeP"
                id="makeP"
                method="POST"
                action="https://paynow.netcash.co.za/site/paynow.aspx"
              >
                <input
                  type="text"
                  name="m1"
                  value="54580e3d-792b-4d97-8c9b-991f287063fd"
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="m2"
                  value="24ade73c-98cf-47b3-99be-cc7b867b3080"
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="p2"
                  value={transaction?.id}
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="m4"
                  value={transaction?.encoded}
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="m5"
                  value={state?.fundRaiser?._id}
                  hidden
                  edit
                />
                <input type="text" name="m6" value={realAmount} hidden edit />
                <input
                  type="text"
                  name="p3"
                  value="Diaspora Kapita"
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="p4"
                  value={netcashAmountValue}
                  hidden
                  edit
                />
                <button
                  type="submit"
                  className="btn btn-secondary"
                  disabled={parseFloat(customAmount) <= 0 || customAmount == ""}
                  style={{
                    marginTop: 10,
                    backgroundColor: !(parseFloat(customAmount) <= 0 || customAmount == "") ? "green" : "#919191",
                  }}
                >
                  Invest Now for {"US$ " + currencyFixer(customAmount || 0)}
                </button>
              </form>
            </div>
          )}
        </div>
      )}

      {/* <Footer /> */}
    </div>
  );
};

export default ClassAShares;
